
import { Vue, Component, Mixins } from "vue-property-decorator";
import { mapState } from "vuex";
import NotFound from "./NotFound.vue";
import CryptoJS from "crypto-js";
import PDFViewer from 'pdf-viewer-vue/dist/vue2-pdf-viewer';
import pdf from 'vue-pdf';
import VuePdfEmbed from 'vue-pdf-embed/dist/vue2-pdf-embed'


// // optional styles
// import 'vue-pdf-embed/src/style/annotationLayer.css'
// import 'vue-pdf-embed/src/style/textLayer.css'

@Component({
  computed: { ...mapState("app", ["school", "mobileView", "tabletView"]) },
  components: {NotFound,PDFViewer,pdf,VuePdfEmbed
  }
})
export default class InsectionReport extends Vue {

    pdfData: any = '';
    index =0;
    showLoader = true;
     doc  ;

     isLoading= true;
      page= null;
      pageCount= 1;
      showAllPages= true;

    async mounted(){
      console.log('param',this.$route.params)

      try
      {
      const value=this.$route.params.param;
      const encryptedParams = JSON.parse(this.decrypt(decodeURIComponent(this.$route.params.param.replaceAll('Edlink','%2B'))));

      const expires = encryptedParams.t;
      if (!expires || Date.now() > parseInt(expires)) // No expiry parameter, consider it expired
       {        
          this.index++;
          this.pdfData=null;
          this.showLoader = false;
       }
      else
      {
        console.log('id',encryptedParams.id);
        await this.$store.dispatch('app/getPdfData',encryptedParams.id).then((result: any) => {
          setTimeout(() => {
            this.index++;
          }, 500)
        this.pdfData=result.obDoc;
        this.doc = 'data:application/pdf;base64,'+this.pdfData.documentData.fileContents;
        this.showLoader = false;
        })
        .catch((err) => {
          console.log(err);
          this.showLoader = false;
        });
        }
      }
      catch(ex)
      {
        console.log('error',ex);
        this.index++;
          this.pdfData=null;
          this.showLoader = false;
      }
    }
    decrypt(message) {
      return CryptoJS.AES.decrypt(
        message,
        "SF"
      ).toString(CryptoJS.enc.Utf8);
    }
    handleDocumentRender(args) {
      console.log(args)
      this.isLoading = false
     // this.pageCount = this.$refs.pdfRef.pageCount
    }
}
